import * as contentfulConstants from '~coreModules/contentful/js/contentful-constants';

export const SUBSCRIPTION_STATUS_ANONYMOUS = 'ANONYMOUS';
export const RECENT_ORDER_TRAY_DESKTOP_SLIDE_SIZE = 6;

export const CONTENTFUL_CUSTOM_MODULE_TYPES = {
    ...contentfulConstants.CONTENTFUL_CUSTOM_MODULE_TYPES,
};

export const CONTENT_TYPES = {
    ...contentfulConstants.CONTENT_TYPES,
    BOX_CONTENT: 'boxContent',
    GIFT_CARD_PRODUCT_PAGE: 'giftCardProductPage',
    CRD_LOCATION: 'customerReportedDamageLocation',
    CRD_LOCATION_SELECTION: 'customerReportedDamagesLocationSelection',
};

export const FILTERED_CONTENT_TYPES = [
    ...contentfulConstants.FILTERED_CONTENT_TYPES,
    CONTENT_TYPES.BOX_CONTENT,
];

export const CONTENT_SLUGS = {
    ...contentfulConstants.CONTENT_SLUGS,
    PAUSE_SURVEY: 'pause-subscription-survey',
    CANCEL_SURVEY: 'cancel-subscription-survey',
    CANCEL_SURVEY_ASSORTMENT: 'cancel-subscription-assortment',
    CANCEL_SURVEY_FREEFORM: 'cancel-subscription-freeform',
    PRODUCT_REVIEW: 'product-review-default',
    REDEEM_CONTENT_SLUG: 'redemptions-content',
    REDEEM_EMPTY_CONTENT_SLUG: 'redemptions-empty-state',
    CONTENTFUL_HOMEPAGE_RECOGNIZED_USER_SLUG: 'recognized-homepage',
    CONTENTFUL_HOMEPAGE_ANONYMOUS_USER_SLUG: 'anonymous-rent-homepage',
    EMPTY_SEARCH_CONTENTFUL_SLUG: 'empty-search',
    BRANDS: 'brands',
    EMPTY_CLOSET_CONTENT_SLUG: 'empty-closet',
    EMPTY_CLOSET_LIST_CONTENT_SLUG: 'empty-sublist',
    EMPTY_CLOSET_LIST_EMPTY_CLOSET_CONTENT_SLUG: 'empty-sublist-empty-closet',
    EMPTY_CLOSET_LIST_NOT_LIST_OWNER: 'empty-sublist-non-owner',
    GIFT_CARDS: 'gift-cards',
    GIFT_CARDS_IMAGE_MARKETING: 'gift-cards-image-marketing',
    JOIN_SUBSCRIBE_PLAN_SLUG: 'onboarding-plan-overview',
    EMPTY_FILTERED_CLOSET_LIST_CONTENT_SLUG: 'no-filter-results-sublists',
    ISSUE_REPORT_DETAILS: 'issue-report-details',
    REWARDS_LEARN_MORE_SLUG: 'rewards-learn-more-content',
    REFERRALS_GENERIC_ERROR_SLUG: 'referrals-generic-error',
    REFERRALS_INELIGIBLE_USER_SLUG: 'referrals-ineligible-user-state',
    REFERRALS_EMAIL_CAPTURE_INELIGIBLE_SLUG: 'referrals-email-capture-ineligible',
    SELF_REFERRAL_EMAIL_CAPTURE_SLUG: 'referrals-email-capture-selfReferral',
};

export const PRODUCT_TRAY_TYPES = {
    closet: 'closet',
    closetSublist: 'closetSublist',
    recommendation: 'recommendation',
    recentOrder: 'recentOrder',
    catalogV2: 'v2Catalog',
    prepurchase: 'prepurchase',
};

export const REALTIME_SLUGS = [
    ...contentfulConstants.GLOBAL_REAL_TIME_SLUGS,
    'account__editNameUsername--title',
    'account__overviewTab--title',
    'account__rentalHistoryTab--title',
    'account__subscriptionTab--title',
    'account__billingReceiptsTab--title',
    'account__profileTab--title',
    'account__redeemTab--title',
    'account__menuItem--overview',
    'account__menuItem--rentalHistory',
    'account__menuItem--subscription',
    'account__menuItem--billing',
    'account__menuItem--profile',
    'account__menuItem--rewards',
    'account__menuItem--giftCards',
    'box__purchasePreview--title',
    'box__reviewBox--title',
    'box__reviewBoxItemsShippingEta--label',
    'box__reviewSubcribeInBoxItems--title',
    'box__title',
    'box__bonusItemPurchase--heading',
    'box__checkForDropOffAnimation--pageTitle',
    'browse__selectSize',
    'browse__shoppableImage--title',
    'browse__size--title',
    'crd__qualityCase--successPageHeader',
    'crd__qualityCase--successPageSubHeader',
    'closet__drawer--title',
    'closet__savedToCloset--confirmation',
    'closet__savedToCloset--addToListCta',
    'contentCards__infoDrawerOne--header',
    'contentCards__infoDrawerTwo--header',
    'communication__preferences--header',
    'employee__invalidId--error',
    'employee__employeeId--label',
    'giftCards__checkout--fromCardError',
    'giftCards__checkout--invalidInputFromCard',
    'giftCard__redeemCta--invalidCode',
    'giftCards__infoModal--header',
    'giftCards__PDP--modalInfoText',
    'giftCards__PDP--recipientNameError',
    'global__success',
    'global__next',
    'global-orderOpen',
    'global-orderInTransit',
    'global-orderInDelivered',
    'global-orderDelivered',
    'global-orderReturnInTransit',
    'global-orderReturned',
    'global-orderCancelled',
    'global-orderReturnException',
    'header__account--label',
    'login__unauthModal--closetTitle',
    'login__unauthModal--closetSubtitle',
    'marketingSmsOptIn__modal--title',
    'meta__account--description',
    'meta__account--title',
    'meta__brand--description',
    'meta__brand--title',
    'meta__brands--title',
    'meta__giftCards--description',
    'meta__giftCards--title',
    'meta__giftCardReveal--title',
    'meta__issueReport--title',
    'meta__pdp--description',
    'meta__referrals--description',
    'meta__referrals--title',
    'meta__rentDefault--title',
    'meta__rentFallback--title',
    'meta__rentHomepage--description',
    'meta__rentHomepage--title',
    'meta__sublistUsername--title',
    'meta__sublistUsernameNull--title',
    'meta__sublist--description',
    'meta__viewSimilar--description',
    'meta__viewSimilar--title',
    'nav__prepurchase--tagComponentText',
    'order__viewReceipt--label',
    'onboarding__inviteCode--noCodeEntered',
    'onboarding__transition--intro',
    'onboarding__transition--introDetails',
    'onboarding__inviteCode--title',
    'onboarding__inviteCode--subtitle',
    'onboarding__inviteCode--label',
    'onboarding__inviteCode--helperText',
    'operationalSmsOptIn__modal--title',
    'payment__editPaymentInfo--title',
    'product__productDetails--title',
    'product__prepurchase--learnMoreContentDrawerTitle',
    'profile__birthday--label',
    'profile__bodyType--label',
    'profile__bust--label',
    'profile__editDisplayOnReviews--title',
    'profile__editEmail--title',
    'profile__editProfile--title',
    'profile__editShippingAddress—title',
    'profile__heightFeet--label',
    'profile__heightInches--label',
    'profile__petitePreferences--label',
    'profile__sizes--label',
    'profile__weightLbs--label',
    'redeem__bonusItemRedeemModal--title',
    'redeem__couponApplyPromoCode--label',
    'redeem__itemCouponRedeemModal--title',
    'redeem__referralDrawer--header',
    'redeem__referFriendsCard--employeeContent',
    'redeem__referFriendsCard--nonEmployeeContent',
    'redeem__subscriptionPromoModalSlide1--title',
    'reviews__customerPhotosGalleryDrawer--title',
    'reviews__imageCarousel--title',
    'reviews__ratingsReviews--title',
    'reviews__readMore--label',
    'subscription__pauseCycles--label',
    'subscription__couponApplied--status',
    'subscription__subscribeTitle--info',
    'subscription__subscribeTitle--title',
    'subscription__subscribe--cta',
    'subscription__reactivateTile--info',
    'subscription__reactivate--cta',
    'subscription__reactivateTitle--title',
    'sublists__addNewList--header',
    'sublists__addToList--header',
    'sublists__deleteList--header',
    'sublists__navLists--header',
    'sublist__renameList--header',
    'sublist__drawer--title',
    'sublists__savedToList--confirmation',
    'sublists__shareList--drawerTitle',
    'sublists__usernameDrawer--drawerTitle',
    'validationError__sublistName--minCharacters',
    'onboarding__transition--intro',
    'onboarding__transition--introDetails',
];

export const {
    colorMap,
    CONTENTFUL_MODULE_TYPES,
    CONDITIONAL_CONTENTFUL_MODULE_TYPES,
    MAX_CONTENTFUL_ROW_COLUMN_OFFSET,
    PRODUCT_TRAY_TRAY_STYLES,
    EDITORIAL_TEXT_BODY_STYLES,
} = contentfulConstants;
